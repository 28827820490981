.eventProfile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


.__event {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.__eventMap {
  width: 100%;
}

.__eventAside {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 56px;
}

.__eventLogo {
  align-self: center;
  width: 300px;
  height: 300px;
}

.__eventAsideActionButtons {
  display: block;
}

.__eventLogoWrapper {

}

.__eventContent {
  flex-grow: 1;
  flex-basis: 1px;
  overflow: hidden;
}

.__eventDateAnnouncement {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.__eventDateText {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.__eventApplicants {
  margin-bottom: 32px;
}

.__eventApplicantsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.__eventApplicant {
  margin-right: 4px;
  margin-bottom: 4px;
}

.__eventLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
}

.__eventDescription {

}

.__applicationStatusIcon {
  display: inline-block;
  color: #fff;
  background: #000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

@media (max-width: 992px) {
  .__event {
    margin-top: 20px;
  }
  .__eventAside {
    display: none;
  }

  .__eventContent h1.__eventTitle {
    font-size: 1.5rem;
  }

  .__eventContent h1 {
    font-size: 2rem;
  }

  .__eventContent h2 {
    font-size: 1.5rem;
  }

  .__eventContent h3 {
    font-size: 1.25rem;
  }

  .__eventContent h4 {
    font-size: 1.25rem;
  }

  .__eventContent h5 {
    font-size: 1.25rem;
  }

  .__eventContent h6 {
    font-size: 1.25rem;
  }
}


