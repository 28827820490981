.userProfile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #f9f9f9;
}

.__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 50%;
  flex-shrink: 0;
}

.__statusText {
  font-size: 150%;
  opacity: 0.5;
}

.__user {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media (min-width: 1600px) {
  .__user {
    max-width: 1500px;
  }
}

@media (min-width: 1920px) {
  .__user {
    max-width: 1820px;
  }
}

.__avatarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 16px;
}

.__actions {
  text-align: center;
  overflow: hidden;
  padding: 4px;
  margin-bottom: 16px;
}

.__socialNetworks {
  text-align: center;
  margin-bottom: 20px;
}

.__aboutWrapper {
  font-size: 80%;
}

.__memberSince {
  font-size: 80%;
  text-transform: uppercase;
  opacity: 0.5;
}

.__gallery {
  margin-left: 16px;
  display: block;
  position: relative;
  flex-grow: 1;
}

@media (max-width: 992px) {
  .__user {
    flex-direction: column;
    justify-content: flex-start;
  }

  .__gallery {
    margin-left: 0;
    margin-top: 16px;
  }

  .__memberSince,
  .__aboutWrapper {
    display: none;
  }

  .__avatarWrapper {
    margin-top: 0;
  }
}
