.__eventTitleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*flex-grow: 1;*/
  margin-bottom: 32px;
  width: 100%;
}

.__eventTitleBlock_compact {
  margin-bottom: 8px;
}

.__eventTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: stretch;
  overflow: hidden;
}

.__eventTitle a {
  color: inherit;
}

.__eventTitleAndSubtitle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1px;
  overflow: hidden;
  margin-right: 16px;
  align-self: center;
}

.__eventSubtitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: stretch;
  overflow: hidden;
  margin: 0;
}

.__dateRange {
  display: flex;
  flex-direction: row;
  background-color: #ccc;
  align-self: center;
  border-radius: 0.25rem;
  height: 80px;
}

.__dateRange_compact {
  height: 65px;
}

.__dateCard {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  flex-grow: 0;
  align-self: center;
  background-color: #eee;
  line-height: 1;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: center;
}

.__dateCard_single {
  background-color: #007bff;
  color: #fff;
}

.__dateCard_compact {
  padding: 8px 12px;
}

.__dateRange .__dateCard:first-of-type {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  align-self: stretch;
  background-color: #007bff;
  color: #fff;
}

.__dateRange .__dateCard:last-of-type {
  padding-left: 8px;
  background-color: inherit;
  align-self: stretch;
}

.__dateCardYear {
  margin-bottom: 4px;
  font-size: 60%;
  opacity: 0.7;
}

.__dateCardMonth {
  opacity: 0.7;
}

.__dateCard_compact .__dateCardMonth {
  font-size: 80%;
}

.__dateCardDay {
  font-size: 200%;
  line-height: 100%;
}

.__dateCard_compact .__dateCardDay {
  font-size: 150%;
}

.__dateRangeSeparator {
  align-self: stretch;
  width: 10px;
}

@media (max-width: 992px) {
  .__eventContent h1.__eventTitle {
    font-size: 1.5rem;
  }

  .__eventTitleBlock {
    margin-bottom: 16px;
  }

  .__eventTitleBlock_compact {
    margin-bottom: 8px;
  }

  .__eventSubtitle {
    font-size: 1rem;
  }

  .__dateRange {
    height: 60px;
    font-size: 70%;
  }

  .__dateCard {
    padding: 4px 12px;
  }
}


