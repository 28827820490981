.__participants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.__participant {
  flex-basis: 50px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}
