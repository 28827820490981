.__modalClose {
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 32px;
  line-height: 100%;
  z-index: 1050;
  background: none;
  border: none;
  padding: 16px;
}
