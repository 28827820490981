.photo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #fff;
}

.__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 50%;
  flex-shrink: 0;
}

.__photoBackground {
  background-color: #999999;
  position: relative;
}

.__photoOwner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.__photoNavigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.__photoNavigationLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  color: #fff;
}

.__photoNavigationRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
  color: #fff;
}

.__photoNavigationLeft:hover,
.__photoNavigationRight:hover {
  color: #ddd;
}

@media (max-width: 768px) {
  .__photoNavigationLeft,
  .__photoNavigationRight {
    margin-right: 0;
    margin-left: 0;
  }
}

.__photoSummary {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-top: 32px;
  align-items: center;
  justify-content: space-between;
}

.__photoInfo {
  display: flex;
  flex-direction: row;
}

.__photoInfoContent {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 60%;
}

.__photoSummaryBasicWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.__participantPicture {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.__photoBasicInfo {
  margin-left: 16px;
  margin-top: 8px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.__photoInfoAside {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 200px;
  padding-left: 16px;
  overflow: hidden;
}

.__photoLike {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
  margin-left: 16px;
}

.__likeButton {
  display: flex;
  align-items: center;
}

.__likeButton:hover {
  color: #e0245e;
}

.__likeButton_liked {
  color: #e0245e;
}

.__likeButton_liked:hover {
  color: inherit;
}

.__photoLikeTitle {
  margin-left: 16px;
  font-weight: 400;
}

.__photoLikeCount {
  margin-left: 16px;
  font-weight: 400;
  font-size: 80%;
  color: #6c757d;
}

@media (max-width: 576px) {
  .__photoLikeCount {
    display: none;
  }
}

.__photoDescription {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0 4px
}

.__photoLicence_editable,
.__photoTitle_editable,
.__photoDescription_editable {
  cursor: text;
  transition: background-color ease 0.5s;
  background-color: rgba(0, 0, 0, 0);
}

.__photoLicence_editable:hover,
.__photoTitle_editable:hover,
.__photoDescription_editable:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.__photoLicence_input,
.__photoTitle_input {
  width: calc(100% - 8px);
  margin: 4px;
}

.__photoPicture {
  background-color: #909090;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  z-index: 1;
}

.__photoOwner {
  padding: 2px 2px 2px 0;
}

@media (max-width: 768px) {
  .__photoInfo {
    flex-direction: column;
  }

  .__photoInfoAside {
    padding-left: 0;
    padding-top: 16px;
  }

  .__photoLike {
    margin-right: 0px;
  }

  .__participantPicture {
    width: 40px;
    height: 40px;
  }

  .__photoSummary {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .__photoBasicInfo {
    margin-top: 0px;
  }

  .__photoTitle {
    font-size: 100%;
    margin-bottom: 4px;
  }

  .__photoOwner {
    font-size: 80%;
    line-height: 100%;
  }

  .__likeButton:hover {
    color: inherit;
  }

  .__likeButton_liked:hover {
    color: #e0245e;
  }
}
