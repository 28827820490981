.__jumboSearch {
  display: flex;
  justify-content: center;
  font-size: 40px;
  border: none;
  font-weight: 300;
  line-height: 3em;
}

.__jumboSearchSizer {
  position: relative;
  min-width: 350px;
}

.__jumboSearchInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: none;
  outline: none;
  background-color: transparent;
}

.__jumboSearchMeasurer {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  visibility: hidden;
}

.__participantSeparator {
  height: 1px;
  background-color: #eee;
  margin: 16px 0px;
}

.__eventCardsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 24px;
  margin-left: -16px;
}

@media (max-width: 728px) {
  .__jumboSearch {
    font-size: 20px;
  }

  .__searchTypeButton {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}
