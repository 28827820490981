.__fullName {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.__occupation {
  font-size: 80%;
  opacity: 0.7;
  text-align: center;
  overflow: hidden;
  max-height: 3rem;
}

.__websiteWrapper {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 16px;
}

.__website {
  font-size: 80%;
  opacity: 0.7;
  color: inherit;
}

.__website:hover {
  color: inherit;
}

.__locationWrapper {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 20px;

  opacity: 0.7;
  color: inherit;
}

.__location {
  font-size: 80%;
  margin-left: 8px;
}

@media (max-width: 992px) {
  .__website,
  .__location,
  .__occupation {
    font-size: 90%;
  }

  .__websiteWrapper {
    margin-bottom: 0px;
  }

  .__locationWrapper {
    margin-bottom: 16px;
  }
}
