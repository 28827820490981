.actionCallback {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 50%;
  flex-shrink: 0;
}

.__actionResponse {
  min-width: 300px;
  max-width: 100%;
  flex-basis: 500px;
  flex-shrink: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
