html, body, #root {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.VZ3eG {
  min-width: 300px;
  max-width: 100%;
  -webkit-flex-basis: 500px;
          flex-basis: 500px;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  margin: 16px;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.VZ3eG input {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

._2_sY5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._1EPNn {
  background-color: #1E88E5;
  display: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 48px;
}

._18PQ8 {
  max-width: 500px;
}

._18PQ8 h1 {
  color: #fff;
}

._18PQ8 a {
  color: #fff;
  text-decoration: none;
}

._18PQ8 p {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

@media (min-width: 768px) {
  ._1EPNn {
    display: -webkit-flex;
    display: flex;
  }
}

.Hjj1T {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

._1lU96 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._3wB30 {
  min-width: 300px;
  max-width: 100%;
  -webkit-flex-basis: 500px;
          flex-basis: 500px;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  margin: 16px;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

._3wB30 input {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

._1jIp- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

._1R3tP {
  padding: 0;
  border: 0;
  background: none;
}

._1C7dQ {
  text-align: center;
}

.tUasa,
.TSztM,
._3CjP1 {
  width: 12px;
  height: 12px;
  margin: 4px;
  background-color: #007bff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: _2ZFm6 1.4s infinite ease-in-out both;
          animation: _2ZFm6 1.4s infinite ease-in-out both;
}

.tUasa {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
}

.TSztM {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
}

@-webkit-keyframes _2ZFm6 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0)
  }
}

@keyframes _2ZFm6 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}



/* Participant */

._3GCpN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 8px 0;
}

._1OiGm {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

._1qwtv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 24px;
  padding-bottom: 4px;
  overflow: hidden;
  -webkit-justify-content: center;
          justify-content: center;
}

._17SxM {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  overflow: hidden;
  max-height: 90px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

._3K4Nv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-basis: 410px;
          flex-basis: 410px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  overflow: hidden;
}

._1Vm32 {
  height: 1px;
  background-color: #eee;
  margin: 16px 0px;
}

._2sgVS {
  width: 90px;
  height: 90px;
  background-color: #eee;
  margin-left: 16px;
}

._1nJVQ {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

._1wUf4 {
  overflow: hidden;
}

._1JRi4,
._1AtIS {
  font-size: 80%;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lnIna {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-top: 8px;
}

@media (max-width: 768px) {
  ._3GCpN {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  ._3K4Nv {
    -webkit-flex-basis: auto;
            flex-basis: auto;
    margin-bottom: 8px;
  }

  ._1OiGm {
    width: 40px;
    height: 40px;
  }

  ._1gyqD,
  ._1AtIS {
    display: none;
  }

  ._1qwtv {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .lnIna {
    margin-top: 0;
    margin-left: 8px;
  }

  ._17SxM {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  ._2sgVS {
    margin-left: 0;
  }
}

._2g_dp {
  border-radius: 50%;
  background-color: #eee;
  font-size: 90%;
  line-height: 125%;
  color: #007bff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

._1hup4 {
  width: 32px;
  height: 32px;
}

._2-nxb {
  width: 45px;
  height: 45px;
}

._1Yw5Y {
  width: 90px;
  height: 90px;
}

._37fWN {
  width: 110px;
  height: 110px;
}

._3AeIA {
  position: relative;
}

._1S2xO {
  position: absolute;
  top: 0;
  right: 0;
  background: #dc3545;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

.GoHpr {
  position: absolute;
  top: 0;
  right: 0;
  background: #ffc107;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}


._3xsri {
  height: 1px;
  background-color: #eee;
  margin: 16px 0px;
}

/* Event */

._1_Rhf {
  overflow: hidden;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: 1px;
          flex-basis: 1px;
  position: relative;
  text-overflow: ellipsis;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  max-height: 120px;
}

._1uSOL {
  overflow: hidden;
}

@media (max-width: 768px) {
  ._1_Rhf {
    display: none;
  }

  ._1uSOL {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
  }

  ._3FQir {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-left: 16px;
  }

  ._3n7uX {
    font-size: 110%;
  }

  ._311T2 {
    font-size: 80%;
  }

  ._1msjL {
    -webkit-align-items: center;
            align-items: center;
  }
}

._3nUul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /*flex-grow: 1;*/
  margin-bottom: 32px;
  width: 100%;
}

._2GlEG {
  margin-bottom: 8px;
}

._2amI1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-align-self: stretch;
          align-self: stretch;
  overflow: hidden;
}

._2amI1 a {
  color: inherit;
}

._3LdFg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: 1px;
          flex-basis: 1px;
  overflow: hidden;
  margin-right: 16px;
  -webkit-align-self: center;
          align-self: center;
}

._21QFJ {
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-align-self: stretch;
          align-self: stretch;
  overflow: hidden;
  margin: 0;
}

._3Ld69 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  background-color: #ccc;
  -webkit-align-self: center;
          align-self: center;
  border-radius: 0.25rem;
  height: 80px;
}

._1dWq7 {
  height: 65px;
}

._3Glay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 8px 16px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-align-self: center;
          align-self: center;
  background-color: #eee;
  line-height: 1;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0.25rem;
  -webkit-justify-content: center;
          justify-content: center;
}

._xB9t {
  background-color: #007bff;
  color: #fff;
}

._1e7J4 {
  padding: 8px 12px;
}

._3Ld69 ._3Glay:first-of-type {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-align-self: stretch;
          align-self: stretch;
  background-color: #007bff;
  color: #fff;
}

._3Ld69 ._3Glay:last-of-type {
  padding-left: 8px;
  background-color: inherit;
  -webkit-align-self: stretch;
          align-self: stretch;
}

.ijJYe {
  margin-bottom: 4px;
  font-size: 60%;
  opacity: 0.7;
}

._2OSSA {
  opacity: 0.7;
}

._1e7J4 ._2OSSA {
  font-size: 80%;
}

._2kbgF {
  font-size: 200%;
  line-height: 100%;
}

._1e7J4 ._2kbgF {
  font-size: 150%;
}

.wudzz {
  -webkit-align-self: stretch;
          align-self: stretch;
  width: 10px;
}

@media (max-width: 992px) {
  .HFw1l h1._2amI1 {
    font-size: 1.5rem;
  }

  ._3nUul {
    margin-bottom: 16px;
  }

  ._2GlEG {
    margin-bottom: 8px;
  }

  ._21QFJ {
    font-size: 1rem;
  }

  ._3Ld69 {
    height: 60px;
    font-size: 70%;
  }

  ._3Glay {
    padding: 4px 12px;
  }
}



._1Ustb {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin: 16px 0;
}

._2iujK {
  min-width: 300px;
  max-width: 100%;
  -webkit-flex-basis: 500px;
          flex-basis: 500px;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  margin: 16px;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

._2iujK input {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.R0Z1D {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

._2Jwv_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.AwZk0 {
  min-width: 300px;
  max-width: 100%;
  -webkit-flex-basis: 500px;
          flex-basis: 500px;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

._389Yk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  background: #f9f9f9;
}

._38xtQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._3FgaT {
  font-size: 150%;
  opacity: 0.5;
}

._17lAe {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

@media (min-width: 1600px) {
  ._17lAe {
    max-width: 1500px;
  }
}

@media (min-width: 1920px) {
  ._17lAe {
    max-width: 1820px;
  }
}

._2x0D8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 24px;
  margin-top: 16px;
}

._3nSuq {
  text-align: center;
  overflow: hidden;
  padding: 4px;
  margin-bottom: 16px;
}

._1bqlw {
  text-align: center;
  margin-bottom: 20px;
}

._3VgVI {
  font-size: 80%;
}

._3Y0sZ {
  font-size: 80%;
  text-transform: uppercase;
  opacity: 0.5;
}

._2dv5h {
  margin-left: 16px;
  display: block;
  position: relative;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

@media (max-width: 992px) {
  ._17lAe {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  ._2dv5h {
    margin-left: 0;
    margin-top: 16px;
  }

  ._3Y0sZ,
  ._3VgVI {
    display: none;
  }

  ._2x0D8 {
    margin-top: 0;
  }
}

.RUJw8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._1iZWP {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

._34xtB {
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.IFBjT {
  display: block;
  position: relative;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.VarhX {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #fff;
  background-size: cover;
}

._3L9mC {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z13p9 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  opacity: 0;
  background-image: linear-gradient(180deg,transparent 0,rgba(0,0,0,.6) 81%);
  padding: 40px 16px 8px 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s linear;
  pointer-events: none;
}

._2iw3_ {
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  font-size: 90%;
  pointer-events: all;
}

._2iw3_:hover {
  color: #fff;
}

._1o9CA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

._3I1GT,
._2eF3m {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
}

._36X9l {
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  font-size: 80%;
  pointer-events: all;
}

._36X9l:hover {
  color: #fff;
}

._12rQn {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  color: #fff;
  font-size: 80%;
}

.z13p9:hover,
._3L9mC:hover + .z13p9 {
  opacity: 1;
}

._32VFG {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._18EZy {
  font-size: 80%;
  opacity: 0.7;
  text-align: center;
  overflow: hidden;
  max-height: 3rem;
}

._2Be2n {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 16px;
}

.K2JSx {
  font-size: 80%;
  opacity: 0.7;
  color: inherit;
}

.K2JSx:hover {
  color: inherit;
}

._2J2P9 {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 20px;

  opacity: 0.7;
  color: inherit;
}

._3BJoP {
  font-size: 80%;
  margin-left: 8px;
}

@media (max-width: 992px) {
  .K2JSx,
  ._3BJoP,
  ._18EZy {
    font-size: 90%;
  }

  ._2Be2n {
    margin-bottom: 0px;
  }

  ._2J2P9 {
    margin-bottom: 16px;
  }
}

._2UeVx {

}

._22-4C {
  width: 360px;
  padding: 24px;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  border-radius: 4px;
  margin: 2px;
  background-color: #fff;
}

@media (max-width: 992px) {
  ._22-4C {
    width: auto;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }
}

._3XYP6 {
  height: 0;
  overflow: hidden;
}

._1VnJO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  background: #fff;
}

._3BVuI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._2Hp-i {
  background-color: #999999;
  position: relative;
}

._3rboW {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._2Fg3G {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

._2kvHB {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 16px;
  color: #fff;
}

._3kQMU {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 16px;
  color: #fff;
}

._2kvHB:hover,
._3kQMU:hover {
  color: #ddd;
}

@media (max-width: 768px) {
  ._2kvHB,
  ._3kQMU {
    margin-right: 0;
    margin-left: 0;
  }
}

.KMHIo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 24px;
  margin-top: 32px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

._eFm_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

._2pNnG {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 60%;
          flex-basis: 60%;
}

._1X7aE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  overflow: hidden;
}

._2wz-y {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

._2ioqZ {
  margin-left: 16px;
  margin-top: 8px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  overflow: hidden;
}

.-ENJ7 {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 200px;
          flex-basis: 200px;
  padding-left: 16px;
  overflow: hidden;
}

._3rC9U {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 24px;
  margin-left: 16px;
}

._1XcOz {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

._1XcOz:hover {
  color: #e0245e;
}

._1V3JR {
  color: #e0245e;
}

._1V3JR:hover {
  color: inherit;
}

._33Mek {
  margin-left: 16px;
  font-weight: 400;
}

._1uiMJ {
  margin-left: 16px;
  font-weight: 400;
  font-size: 80%;
  color: #6c757d;
}

@media (max-width: 576px) {
  ._1uiMJ {
    display: none;
  }
}

.IT5VP {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0 4px
}

.JqZLI,
._8evFY,
._3XyUS {
  cursor: text;
  transition: background-color ease 0.5s;
  background-color: rgba(0, 0, 0, 0);
}

.JqZLI:hover,
._8evFY:hover,
._3XyUS:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

._2Xsgq,
.BQMkV {
  width: calc(100% - 8px);
  margin: 4px;
}

.l5fX6 {
  background-color: #909090;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  z-index: 1;
}

._3rboW {
  padding: 2px 2px 2px 0;
}

@media (max-width: 768px) {
  ._eFm_ {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .-ENJ7 {
    padding-left: 0;
    padding-top: 16px;
  }

  ._3rC9U {
    margin-right: 0px;
  }

  ._2wz-y {
    width: 40px;
    height: 40px;
  }

  .KMHIo {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  ._2ioqZ {
    margin-top: 0px;
  }

  ._3vaIy {
    font-size: 100%;
    margin-bottom: 4px;
  }

  ._3rboW {
    font-size: 80%;
    line-height: 100%;
  }

  ._1XcOz:hover {
    color: inherit;
  }

  ._1V3JR:hover {
    color: #e0245e;
  }
}

.oI1fG {
  width: 32px;
  height: 32px;
  border-width: 0;
  padding: 0;
  margin: 4px 0;
  border-radius: 50%;
}

._1ya43 {
  margin-right: 16px;
}

._1ya43:hover {
  color: #bbb;
}

._1mbJ- {
  margin-left: 4px;
}

._2Mawb {
  position: absolute;
  right: 0;
}

._3yS6t {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 40px;
  border: none;
  font-weight: 300;
  line-height: 3em;
}

._36Mg9 {
  position: relative;
  min-width: 350px;
}

._3W0fc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: none;
  outline: none;
  background-color: transparent;
}

._2Cw49 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  visibility: hidden;
}

._2YeIa {
  height: 1px;
  background-color: #eee;
  margin: 16px 0px;
}

.OZMcA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 24px;
  margin-left: -16px;
}

@media (max-width: 728px) {
  ._3yS6t {
    font-size: 20px;
  }

  ._2rHgG {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}

._1K1sV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}


._1ULWI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 48px;
}

._2MOlm {
  width: 100%;
}

._2PVYT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 300px;
  margin-right: 56px;
}

._3iuXa {
  -webkit-align-self: center;
          align-self: center;
  width: 300px;
  height: 300px;
}

._2GuwZ {
  display: block;
}

._2AFfm {

}

.Eu-DA {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 1px;
          flex-basis: 1px;
  overflow: hidden;
}

.MIC5V {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 16px;
}

.Qdjit {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  margin-right: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

._3961E {
  margin-bottom: 32px;
}

._31KiE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

._1VsL0 {
  margin-right: 4px;
  margin-bottom: 4px;
}

._31J3a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
}

._3YS1E {

}

._25WVh {
  display: inline-block;
  color: #fff;
  background: #000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
}

@media (max-width: 992px) {
  ._1ULWI {
    margin-top: 20px;
  }
  ._2PVYT {
    display: none;
  }

  .Eu-DA h1._17Efg {
    font-size: 1.5rem;
  }

  .Eu-DA h1 {
    font-size: 2rem;
  }

  .Eu-DA h2 {
    font-size: 1.5rem;
  }

  .Eu-DA h3 {
    font-size: 1.25rem;
  }

  .Eu-DA h4 {
    font-size: 1.25rem;
  }

  .Eu-DA h5 {
    font-size: 1.25rem;
  }

  .Eu-DA h6 {
    font-size: 1.25rem;
  }
}



.iNOfA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.OubaW {
  -webkit-flex-basis: 50px;
          flex-basis: 50px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 4px;
}

._3-2uT {
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 32px;
  line-height: 100%;
  z-index: 1050;
  background: none;
  border: none;
  padding: 16px;
}

