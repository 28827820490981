.__userCardPlaceholder {

}

.__userCard {
  width: 360px;
  padding: 24px;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  border-radius: 4px;
  margin: 2px;
  background-color: #fff;
}

@media (max-width: 992px) {
  .__userCard {
    width: auto;
    flex-grow: 1;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
  }
}
