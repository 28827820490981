.__gallery {
  display: block;
  position: relative;
  flex-grow: 1;
}

.__galleryPicture {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #fff;
  background-size: cover;
}

.__galleryPictureCover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.__galleryPictureInfo {
  display: flex;
  flex-direction: column;
  opacity: 0;
  background-image: linear-gradient(180deg,transparent 0,rgba(0,0,0,.6) 81%);
  padding: 40px 16px 8px 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s linear;
  pointer-events: none;
}

.__galleryPictureTitle {
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  font-size: 90%;
  pointer-events: all;
}

.__galleryPictureTitle:hover {
  color: #fff;
}

.__galleryPictureInfoSecondary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.__galleryPictureTitleWrapper,
.__galleryPictureAuthorLinkWrapper {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
}

.__galleryPictureAuthorLink {
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  font-size: 80%;
  pointer-events: all;
}

.__galleryPictureAuthorLink:hover {
  color: #fff;
}

.__galleryPictureLikeCount {
  flex-shrink: 0;
  color: #fff;
  font-size: 80%;
}

.__galleryPictureInfo:hover,
.__galleryPictureCover:hover + .__galleryPictureInfo {
  opacity: 1;
}
