.__userMenuPicture {
  width: 32px;
  height: 32px;
  border-width: 0;
  padding: 0;
  margin: 4px 0;
  border-radius: 50%;
}

.__uploadPhotoLink {
  margin-right: 16px;
}

.__uploadPhotoLink:hover {
  color: #bbb;
}

.__uploadPhotoText {
  margin-left: 4px;
}

.__unreadConversationsBadge {
  position: absolute;
  right: 0;
}
