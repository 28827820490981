/* Event */

.__eventDescription {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1px;
  position: relative;
  text-overflow: ellipsis;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  max-height: 120px;
}

.__eventMainContent {
  overflow: hidden;
}

@media (max-width: 768px) {
  .__eventDescription {
    display: none;
  }

  .__eventMainContent {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .__eventActions {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .__eventTitle {
    font-size: 110%;
  }

  .__eventShortInfo {
    font-size: 80%;
  }

  .__eventInfo {
    align-items: center;
  }
}
