.__profilePicture {
  border-radius: 50%;
  background-color: #eee;
  font-size: 90%;
  line-height: 125%;
  color: #007bff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.__profilePicture_small {
  width: 32px;
  height: 32px;
}

.__profilePicture_medium {
  width: 45px;
  height: 45px;
}

.__profilePicture_normal {
  width: 90px;
  height: 90px;
}

.__profilePicture_big {
  width: 110px;
  height: 110px;
}
