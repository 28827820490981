.spinner {
  text-align: center;
}

.__bounceFirst,
.__bounceSecond,
.__bounceThird {
  width: 12px;
  height: 12px;
  margin: 4px;
  background-color: #007bff;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.__bounceFirst {
  animation-delay: -0.32s;
}

.__bounceSecond {
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0)
  }
  40% {
    transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}
