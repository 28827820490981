.block {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
}

.__aside {
  background-color: #1E88E5;
  display: none;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 50%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.__asideBanner {
  max-width: 500px;
}

.__asideBanner h1 {
  color: #fff;
}

.__asideBanner a {
  color: #fff;
  text-decoration: none;
}

.__asideBanner p {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

@media (min-width: 768px) {
  .__aside {
    display: flex;
  }
}

.__contentWrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 1;
}

.__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 50%;
  flex-shrink: 0;
}
