

/* Participant */

.__participant {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
}

.__participantPicture {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.__participantInfo {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 24px;
  padding-bottom: 4px;
  overflow: hidden;
  justify-content: center;
}

.__participantGallery {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;
  max-height: 90px;
  flex-wrap: wrap;
}

.__participantMain {
  display: flex;
  flex-direction: row;
  flex-basis: 410px;
  flex-shrink: 0;
  overflow: hidden;
}

.__participantSeparator {
  height: 1px;
  background-color: #eee;
  margin: 16px 0px;
}

.__participantImage {
  width: 90px;
  height: 90px;
  background-color: #eee;
  margin-left: 16px;
}

.__participantImageContent {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.__participantInfoPrimary {
  overflow: hidden;
}

.__participantOccupation,
.__participantLocation {
  font-size: 80%;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.__participantActions {
  flex-shrink: 0;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .__participant {
    flex-direction: column;
  }

  .__participantMain {
    flex-basis: auto;
    margin-bottom: 8px;
  }

  .__participantPicture {
    width: 40px;
    height: 40px;
  }

  .__participantFollowers,
  .__participantLocation {
    display: none;
  }

  .__participantInfo {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .__participantActions {
    margin-top: 0;
    margin-left: 8px;
  }

  .__participantGallery {
    justify-content: space-between;
  }

  .__participantImage {
    margin-left: 0;
  }
}
