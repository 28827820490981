.__webAccountLink {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

.__webAccountIcon {
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
