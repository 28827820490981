.__participantIconStatusWrapper {
  position: relative;
}

.__fullyBookedIcon {
  position: absolute;
  top: 0;
  right: 0;
  background: #dc3545;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

.__starredIcon {
  position: absolute;
  top: 0;
  right: 0;
  background: #ffc107;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

