.block {
  min-width: 300px;
  max-width: 100%;
  flex-basis: 500px;
  flex-shrink: 1;
  margin: 16px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block input {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
